import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function CardItem({
  name,
  thumbnail: {
    title,
    imageFile: { childImageSharp },
  },
}) {
  return (
    <div className='caroussel-item'>
      <GatsbyImage
        className='caroussel-item-icon'
        image={getImage(childImageSharp)}
        alt={title}
        placeholder='blurred'
      />
      <div>
        <span className='caroussel-item-title'>{name}</span>
      </div>
    </div>
  )
}

export default CardItem
