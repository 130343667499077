import React from 'react'
import Page from '../Layout/Page'
import PageWithPartners from '../Layout/PageWithPartners'
import { graphql } from 'gatsby'

function Formations({
  data: {
    directus: {
      page: { title, hero, items, partners, ...page },
    },
  },
}) {
  return (
    <Page selected='Courses' title={title} description="Ahmed meftah's courses">
      <PageWithPartners
        nav={{
          title: hero.title,
          bg1: hero.background1,
          bg2: hero.background2,
          color: hero.textColor,
          waveBg1: '#ffffff',
          waveBg2: '#ffffff',
        }}
        items={items}
        partners={partners}
      />
    </Page>
  )
}

export default Formations

export const query = graphql`
  query {
    directus {
      page: Partner_page_by_id(id: "5a60877d-4d38-48ad-8570-e9f3f9166190") {
        title
        hero {
          background1
          background2
          textColor
          title
        }
        partners {
          id
          partner_id {
            name
            link
            id
            thumbnail {
              id
              imageFile {
                id
                childImageSharp {
                  gatsbyImageData(width: 300, height: 300, quality: 100)
                }
              }
            }
          }
        }
        items {
          id
          item {
            ... on DirectusData_courses {
              id
              name
              thumbnail {
                id
                title
                imageFile {
                  childImageSharp {
                    gatsbyImageData(width: 300, height: 300, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
