import React from 'react'
import Nav from '../component/Nav'
import Wave from '../component/Wave'
import CardItem from '../component/CardItem'

function PageWithPartners({ nav = {}, items = [], partners = [] }) {
  return (
    <div id='cv'>
      <Nav {...nav} />
      <div className='container'>
        <div className='caroussel'>
          {items.map(({ id, item }) => (
            <CardItem key={id} {...item} />
          ))}
        </div>
      </div>
      <Wave fg={'#29674b'} bg={'#27225c'} dontshow={partners.length === 0} />
    </div>
  )
}

export default PageWithPartners
